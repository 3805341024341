<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ blogDescriptions.englishBlogName }}</h1>
    </div>
    <div class="servicesAddPage__steps w-100">
      <div
        @click="stepTwo = false"
        class="servicesAddPage__step servicesEditPage__step cursor-pointer"
        :class="{ 'servicesAddPage__step-active': !stepTwo }"
      >
        {{ $t("Description") }}
      </div>
      <div
        @click="stepTwo = true"
        class="servicesAddPage__step servicesEditPage__step cursor-pointer"
        :class="{ 'servicesAddPage__step-active': stepTwo }"
      >
        {{ $t("Thumbnail") }}
      </div>
    </div>
    <div class="servicesAddPage">
      <div v-if="!stepTwo">
        <div class="d-flex justify-content-center">
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.english }"
            @click="languageChange('english')"
          >
            {{ $t("French") }}
          </div>
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.french }"
            @click="languageChange('french')"
          >
            {{ $t("English") }}
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-6">
            <base-input
              v-show="language.english"
              v-model="blogDescriptions.englishBlogName"
              class="servicesAddPage__input expertAddPage__input mb-0"
              :error="v$.blogDescriptions.englishBlogName.$error"
              :error-message="v$.blogDescriptions.englishBlogName.$errors"
            >
              {{ $t("BlogTitle") }}*
            </base-input>
            <base-input
              v-show="language.french"
              v-model="blogDescriptions.frenchBlogName"
              class="servicesAddPage__input expertAddPage__input mb-0"
              :error="v$.blogDescriptions.frenchBlogName.$error"
              :error-message="v$.blogDescriptions.frenchBlogName.$errors"
            >
              {{ $t("BlogTitle") }}*
            </base-input>
          </div>
          <div class="col-6">
            <div class="expertAddPage__input mb-0">
              <span
                :class="{
                  'baseInput__area-focus expertAddPage__input-label':
                    blogDescriptions.expert,
                }"
                class="baseInput__label"
              >
                {{ $t("employeeCalendarSelectExpert") }}
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                placeholder=""
                :show-labels="false"
                label="full_name"
                track-by="full_name"
                v-model="blogDescriptions.expert"
                open-direction="bottom"
                :options="experts"
                :searchable="true"
              >
              </vue-multiselect>
            </div>
          </div>
        </div>
        <div class="row mt-50 mb-10">
          <div class="col-10">
            <div class="expertAddPage__input">
              <span
                :class="{
                  'baseInput__area-focus expertAddPage__input-label':
                    blogDescriptions.tags,
                }"
                class="baseInput__label"
              >
                {{ $t("PostTags") }}
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                :multiple="true"
                :taggable="true"
                :options="tags"
                v-model="blogDescriptions.tags"
                placeholder=""
                :show-labels="false"
                openDirection="bottom"
                label="name"
                track-by="name"
              />
            </div>
          </div>
          <div class="col-2">
            <base-button class="addTag_button" @click="showTagPopup()">
              {{ $t("AddNewTag") }}
            </base-button>
          </div>
        </div>
        <div class="servicesAddPage__titleContainer">
          <div class="servicesAddPage__title">{{ $t("PostContent") }}</div>
        </div>
        <div class="servicesAddPage__fullDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="blogDescriptions.fullDescriptionValue.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="blogDescriptions.fullDescriptionValue.french"
            ></ckeditor>
          </div>
        </div>
      </div>
      <div v-show="stepTwo" class="position-relative">
        <div v-if="!!!thumbnail" class="servicesAddPage__oldImage-wrapper">
          <image-service
            v-if="oldImage"
            class="servicesAddPage__oldImage"
            size="500"
            :single-service="true"
            :service_slug="blog_slug"
            :image-name="oldImage"
          />
        </div>
        <drag-and-drop ref="thumbnail" v-model="thumbnail" />
      </div>
      <div
        class="servicesAddPage__footer"
        :class="{ 'justify-content-between': stepTwo && thumbnail }"
      >
        <base-button
          v-if="stepTwo && thumbnail"
          class="popup__remove-logo-btn"
          @click="removeImage()"
        >
          {{ $t("RemoveThumbnail") }}
        </base-button>
        <div class="d-flex align-items-center">
          <div
            v-if="stepTwo"
            @click="stepTwo = false"
            class="servicesAddPage__cancel"
          >
            {{ $t("back") }}
          </div>
          <base-button v-if="!stepTwo" @click="nextStep()"
            >{{ $t("nextStep") }}
          </base-button>
          <base-button
            v-if="stepTwo"
            :is-loaded="imageIsLoaded"
            @click="createBlogPost()"
          >
            {{ $t("submit") }}
          </base-button>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import ImageService from "@/components/UI/images/ImageService.vue";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow.vue";
import DragAndDrop from "@/components/elements/DragAndDrop.vue";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";

export default {
  name: "BlogAddPage",
  components: {
    ImageService,
    HorizontalArrow,
    DragAndDrop,
    BaseButton,
    BaseInput,
    MainLayout,
    VueMultiselect,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      removeLogoState: false,
      informationBackups: {},
      editor: ClassicEditor,
      imageIsLoaded: false,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "undo",
            "redo",
          ],
        },
      },
      haveChanges: true,
      stepTwo: false,
      oldImage: null,
      blog_slug: null,
      blog_id: null,
      thumbnail: "",
      experts: [],
      language: {
        english: false,
        french: true,
      },
      blogDescriptions: {
        fullDescriptionValue: {
          english: "",
          french: "",
        },
        englishBlogName: null,
        frenchBlogName: null,
        expert: null,
        tags: null,
      },
    };
  },
  validations() {
    return {
      blogDescriptions: {
        englishBlogName: { required },
        frenchBlogName: { required },
      },
    };
  },
  mounted() {
    this.getExpert();
    this.getTags();
    this.$store.commit("SET_LOADED_STATE", false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  methods: {
    getExpert() {
      this.$http
        .get("/experts", {
          params: {
            notActivated: true,
            blocked: true,
          },
        })
        .then(({ data }) => {
          this.experts = data.body;
        });
    },
    discardImage() {
      this.$refs.thumbnail.remove();
      this.thumbnail = null;
    },
    languageChange(language) {
      for (let i in this.language) {
        this.language[i] = i !== language;
      }
    },
    removeImage() {
      this.thumbnail = null;
      this.removeLogoState = true;
      this.$refs.thumbnail.remove();
    },
    nextStep() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.stepTwo = true;
    },
    getTags() {
      this.$http.get("/blog/tags").then(({ data }) => {
        this.$store.commit("SET_BLOG_TAGS", data.body.reverse());
      });
    },
    createBlogPost() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      let tagList = [];
      if (this.blogDescriptions.tags) {
        this.blogDescriptions.tags.forEach((tag) => {
          tagList.push(tag.id);
        });
      }

      this.$http
        .post("/blog/posts", {
          image: this.thumbnail,
          expert_id: this.blogDescriptions.expert
            ? this.blogDescriptions.expert.user_id
            : null,
          translations: [
            {
              language_id: 1,
              title: this.blogDescriptions.englishBlogName,
              content: this.blogDescriptions.fullDescriptionValue.english,
            },
            {
              language_id: 2,
              title: this.blogDescriptions.frenchBlogName,
              content: this.blogDescriptions.fullDescriptionValue.french,
            },
          ],
          tags: tagList,
        })
        .then(() => {
          this.haveChanges = true;
          this.$store.commit("SET_LOADED_STATE", false);
          this.toast.success(this.$t("successfulCreateService"));
          this.$store.commit("SET_BLOG_ITEMS", []);
          this.$store.commit("SET_BLOG_LATEST_POST", null);
          this.$router.push("/blog");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.haveChanges = false;
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    showTagPopup() {
      this.$store.commit("SET_ADD_NEW_TAG", true);
    },
  },
  computed: {
    popupsInformation() {
      return this.$store.getters.STATE_POPUP;
    },
    ...mapGetters({
      imageIsLoad: "LOADED_STATE",
      tags: "BLOG_TAGS",
    }),
  },
  watch: {
    serviceDescriptions: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
      },
    },
    tags: {
      deep: true,
      handler(value) {
        if (value.length === 0) {
          this.getTags();
        }
      },
    },
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.haveChanges = true;
          this.editServices();
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
      },
    },
  },
};
</script>

<style></style>
